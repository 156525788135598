import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { AboutNav } from 'components/organisms/aboutNav';
import { AboutSection } from 'components/organisms/aboutSection';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import sizes from 'style/sizes';
import { mq, breakpoint } from 'style/variables';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { AboutContent, ImgsObject } from 'components/organisms/aboutContent';
import { EnHeading } from 'components/atoms/enHeading/index';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const MainText = styled.div`
  margin-top: ${sizes.margin[64]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
  }
  p {
    font-family: ${fonts.jaGothic};

    & + p {
      margin-top: 2em;
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;

const MainTextRule = styled(MainText)`
  margin-top: unset;
  ${mq.onlysp} {
    margin-top: unset;
  }
  margin-bottom: 48px;
`;

const AboutText = styled(Text)`
  font-family: ${fonts.jaGothic};
  ${text(15, 2.5)};
  ${mq.onlysp} {
    ${text(15, 2.2)};
  }
  & + & {
    margin-top: 3.7em;
    ${mq.onlysp} {
      margin-top: 3em;
    }
  }
`;

const Images: ImgsObject[] = [
  {
    path: 'about/culture/img-culture01.jpg',
    alt: '',
  },
  {
    path: 'about/culture/img-culture02.jpg',
    alt: '',
  },
  {
    path: 'about/culture/img-culture03.jpg',
    alt: '',
  },
];

const CultureSubHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${fonts.enGothic};
  font-size: 15px;
  font-weight: 700;
  line-height: 3.33;

  &::after {
    content: '';
    background-color: #000;
    opacity: 0.5;
    width: 13px;
    height: 1px;
  }
`;

const RuleWrapper = styled.div`
  font-family: ${fonts.jaGothic};
`;

const Pages: React.FC<IndexPageProps> = () => {
  const [isSp, setIsSp] = useState(false);

  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
  }, []);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <>
      <SEO title={'Culture | About'} />
      <ContentWrapper>
        <AboutNav
          currentPage="Culture"
          links={[
            { text: 'Company', link: '/en/about/company' },
            { text: 'Studios', link: '/en/about/studios' },
            { text: 'Vision', link: '/en/about/vision' },
            { text: 'Culture', link: '/en/about/culture', active: true },
            { text: 'Sustainability', link: '/en/about/sustainability' },
          ]}
        />
        <AboutSection>
          <FadeUpSection>
            <EnHeading>To build a better future as a team...</EnHeading>
            <MainText>
              <AboutText>
                FOURDIGIT brings together diverse team members of different ages and nationalities. It is exactly
                because we are a diverse and complex team that we want to keep close our underlying ways of life,
                attitudes, and values. That underlying way of thinking is written in FOURDIGIT’s principles: Identity,
                Mission, and Rules. They represent our stance, our orientation toward constant growth, for a better
                future. This stance, facing what’s in front of us with all our power, is in FOURDIGIT’s nature.
              </AboutText>
            </MainText>
          </FadeUpSection>
        </AboutSection>
        <AboutContent key={``} imgs={Images} isImgRight={false}>
          <AboutSection>
            <FadeUpSection>
              <CultureSubHeading>Identity</CultureSubHeading>
              <EnHeading>Pursuing the happiness of everyone involved</EnHeading>
            </FadeUpSection>
            <FadeUpSection>
              <MainText>
                <AboutText>
                  FOURDIGIT’s company Identity is to pursue the happiness of everyone involved. We deliver services and
                  products that bring happiness, joy, to our customers, partners, and everyone beyond, with the
                  happiness of our staff as the starting point. That brings forth gratitude, in turn bringing happiness
                  to our staff. We are committed to maintaining this good kind of environment, a harmonized environment.
                </AboutText>
              </MainText>
            </FadeUpSection>
          </AboutSection>
          <AboutSection>
            <FadeUpSection>
              <CultureSubHeading>Mission</CultureSubHeading>
              <EnHeading>To grow through work</EnHeading>
              <MainText>
                <AboutText>
                  FOURDIGIT is oriented toward growth. We engage with our work, seriously and with all our powers, which
                  means firstly that we grow as individuals and ultimately that the firm grows, so there is mutual
                  growth. That is what we aim for. This growth is multifaceted, more than just capabilities or sales,
                  and ultimately leads to growth as human beings, improvement of our humanity. This, in turn, brings
                  happiness to everyone involved.
                </AboutText>
              </MainText>
            </FadeUpSection>
          </AboutSection>
          <AboutSection>
            <FadeUpSection>
              <CultureSubHeading>Rule</CultureSubHeading>
              <MainTextRule>
                <AboutText>
                  The way we think and act on a daily basis is especially important for realizing our Identity and
                  Mission. By “Rules,” we don’t mean “rules to keep an eye on behavior.” It’s precisely because our
                  Rules are an everyday matter that we have set them out as something for us to reflect on ourselves,
                  not just to follow because someone tells us.
                </AboutText>
              </MainTextRule>
              <RuleWrapper>
                1. Use beautiful language. <br />
                2. Enjoy what you do. <br />
                3. Respect the people you’re with. <br />
                4. Make customers happy. <br />
                5. Act in good conscience. <br />
                6. Focus on speed. <br />
                7. Motivate yourself. <br />
                8. Put all your powers into your work.
              </RuleWrapper>
            </FadeUpSection>
          </AboutSection>
        </AboutContent>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutCultureEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
